<template>
  <div class="flex flex-col min-h-screen bg-neutral-50">
    <the-navbar/>
    <main id="content" class="flex flex-col">
      <home-banner/>
      <div class="flex flex-col gap-24 mt-24 mb-40">
        <slot/>
      </div>
    </main>
    <the-footer class="mt-auto"/>
  </div>
</template>
